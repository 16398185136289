import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { autoUnderwritingMetadata } from '@/services/autoApi'
import { ref } from 'vue'
import { Ref } from 'vue/types/v3-generated'
import { logger } from '@/utils/logger'
import { toFormattedAprStripTrailingZerosAfterDecimalPoint } from '@/mixins/format'
import { UnderwritingMetadata } from 'aven_types'
import { tryParseJson } from '@/utils/stringUtils'
import assert from 'assert'

export const HIGH_BT_CO_FEE_VARIABLE_NAME = 'IncreasedBtCoVariant'
export const PRIME_V2_VARIABLE_NAME = 'PrimeV2Variant'

export const IGNORE_STATED_USAGE_VARIABLE_NAME = 'IgnoreStatedUsageVariant'

/**
 * DEPRECATED: All in the wild uses of this fn should be replaced with useUnderwritingMetadata
 * and passed from a vue component so vue can manage the reactivity.
 * @deprecated
 */
export const getUnderwritingMetadata = (): UnderwritingMetadata => {
    const dataStr = appSessionStorage.getItem(sessionStorageKey.underwritingMetaData)
    if (!dataStr) {
        throw new Error('Underwriting metadata is not set in session storage')
    }

    const uwMetaData = tryParseJson<UnderwritingMetadata>(dataStr)
    assert(uwMetaData, 'Failed to parse underwriting metadata from session storage')
    return uwMetaData
}

export const isUnderwritingMetadataReady = (): boolean => {
    const data = appSessionStorage.getItem(sessionStorageKey.underwritingMetaData)
    return !!data
}

export const getAutoUnderwritingMetadata = async (): Promise<UnderwritingMetadata> => {
    const autoUnderwritingMetadataResponse = await autoUnderwritingMetadata()
    return autoUnderwritingMetadataResponse.data.payload.underwritingMetadata
}

export const maybeSetUnderwritingMetadata = (uwMetadata?: UnderwritingMetadata): void => {
    if (!uwMetadata) {
        return
    }

    appSessionStorage.setItemIfPresent(sessionStorageKey.underwritingMetaData, JSON.stringify(uwMetadata))
    // Let the app know that the uw metadata is ready
    const uwMetadataObtainedEvent = new CustomEvent('uw-metadata-obtained', { detail: uwMetadata })
    window.dispatchEvent(uwMetadataObtainedEvent)
}

export const useUnderwritingMetadata = (): Ref<UnderwritingMetadata> => {
    const uwMetaData = ref({} as UnderwritingMetadata)

    try {
        uwMetaData.value = getUnderwritingMetadata()
    } catch (error) {
        logger.error('Failed to get underwriting metadata', error)
    }

    window.addEventListener('uw-metadata-obtained', () => {
        try {
            uwMetaData.value = getUnderwritingMetadata()
        } catch (error) {
            logger.error('Failed to get underwriting metadata', error)
        }
    })

    return uwMetaData
}

export const getCashbackRatio = (uwMetadata: UnderwritingMetadata): string => {
    const hasBoostCashback = !!uwMetadata.boostCashBackRatio && uwMetadata.boostCashBackRatio > 0 && !uwMetadata.boostCashBackWithTargetMerchants

    if (hasBoostCashback) {
        return toFormattedAprStripTrailingZerosAfterDecimalPoint(uwMetadata.boostCashBackRatio ?? 0)
    }
    return toFormattedAprStripTrailingZerosAfterDecimalPoint(uwMetadata.cashbackRatio ?? 0)
}
